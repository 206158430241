.article__text-container {
  .article__section {
    @include articleTitle-section-label();
    margin-bottom: $gapSmall * 0.5;
    font-family: $fontSansSerif;
    font-weight: 600;
    text-transform: none;

    @media (max-width: 768px) {
      @include articleTitle-section-label-mobile();
    }

    .column--big & {
      @include articleTitle-section-label-big();
    }

    .article__section--kortfortalt {
      background-image: url('/seksjon/dist/img/story/kort-fortalt.svg');
      background-size: contain;
      background-repeat: no-repeat;
      width: 106px;
      height: 26px;
      padding: 1px 0 0 8px;
      box-sizing: border-box;
      display: inline-block;
      color: #0174b0;
      font-size: 12px;
      letter-spacing: 0.5px;
    }

    .article__section--valg-2021 {
      background-image: url('https://cdn.bt.no/bt/valg2021/valg2021-logo.svg');
      background-size: contain;
      background-repeat: no-repeat;
      width: 54px;
      height: 16px;
      box-sizing: border-box;
      display: inline-block;
      position: relative;
      top: 2px;
    }

    .article__section--valg-2023 {
      background-image: url('https://cdn.bt.no/bt/valg2021/valg2021-logo.svg');
      background-size: contain;
      background-repeat: no-repeat;
      width: 54px;
      height: 16px;
      box-sizing: border-box;
      display: inline-block;
      position: relative;
      top: 2px;
    }

    // SA valg 2021
    .article__section--stortingsvalg {
      background-image: url('https://cdn.aftenbladet.no/sa/sa-valg-21.svg');
      background-size: contain;
      background-repeat: no-repeat;
      width: 128px;
      height: 18px;
      box-sizing: border-box;
      display: inline-block;
      color: #222;
      text-align: right;
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }

    .article__section--usavalget {
      background-image: url('/seksjon/dist/img/story/usavalget2020.svg');
      background-size: contain;
      background-repeat: no-repeat;
      width: 116px;
      height: 18px;
      box-sizing: border-box;
      display: inline-block;
      color: #222;
      text-align: right;
      font-size: 11px;
      font-weight: 600;
      letter-spacing: 0.5px;
      text-transform: uppercase;

      @include respond-to(mobile-only) {
        width: 126px;
      }
    }

    .article__section--usavalget24 {
      background-image: url('/seksjon/dist/img/story/usa-24-logo.svg');
      background-size: contain;
      background-repeat: no-repeat;
      display: inline-block;
      width: 74px;
      height: 18px;

      @include respond-to(mobile-only) {
        width: 74px;
      }
    }

    .article__section--valg-sa-2023 {
      background-image: url('https://cdn.bt.no/aftenbladet/valg_2023.svg');
      background-size: contain;
      background-repeat: no-repeat;
      width: 65px;
      height: 22px;
      box-sizing: border-box;
      display: inline-block;
      position: relative;
      top: 2px;
      display: block;
      margin-bottom: 8px;
    }

    .article__section--valgstudio-sa-2023 {
      background-image: url('https://cdn.bt.no/aftenbladet/valgstudiologo-2023.svg');
      background-size: contain;
      background-repeat: no-repeat;
      width: 142px;
      height: 22px;
      box-sizing: border-box;
      display: inline-block;
      position: relative;
      top: 2px;
      display: block;
      margin-bottom: 8px;
      background-position-x: left;
      flex-shrink: 0;
    }

    .article__section--julekalender {
      font-family: $fontSansSerif;
      font-weight: 500;
      color: #a73a83;
      font-size: 11px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }

    .article__section--bergens-beste {
      color: #b21c64;
    }

    .article__section--klimatoppmote {
      color: #49943b;
    }

    .article__section--slik-mottes-vi {
      color: #397b10;
    }

    .article__section--makten-paa-askoy {
      color: #333;
    }

    .article--live & {
      .article__section--usavalget,
      .article__section--valg-2021 {
        margin-left: $gapStandard;
      }
    }

    .article--live:not(
        .article--breaking,
        :has(.article__section--valgstudio-sa-2023)
      )
      & {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;

      &::before {
        content: '';
        border: none;
        border-radius: 50%;
        width: 8px;
        height: 8px;
        margin-top: 0;
      }
    }
  }

  .e24-icon {
    display: inline;
    margin-left: 0.3em;
    position: relative;

    img {
      height: 1em;
      position: absolute;
      top: 0.15em;
    }
  }
}
.article--dark {
  .article__teaser-container {
    .article__link {
      .article__text-container {
        .article__section {
          .article__section--valg-sa-2023 {
            background-image: url('https://cdn.bt.no/aftenbladet/valg_2023-white.svg');
          }
        }
      }
    }
  }
}

.column--big {
  .article--live:not(.article--breaking) {
    .article__text-container {
      .article__section {
        &::before {
          margin-top: -1px;
          width: 10px;
          height: 10px;
        }
      }
    }
  }
}

.Leder,
.Debatt,
.Kommentar {
  .article__section {
    display: none;
  }

  .author__image-container {
    margin-top: 0;
  }
}

// TODO: always without quote now?
.article--withoutQuote .article__section {
  padding-left: 0 !important;
  &::before {
    display: none;
  }
}

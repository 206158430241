#buy-abo-stripe-abo {
    font-family: Inter;
    color: var(--buy-abo-text-color, #02476c);
    background: var(--buy-abo-background, #eef9ff);
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 8px 12px 8px 14px;
    display: flex;

    div {
        font-size: 14px;
        line-height: 18px;
        font-weight: 500;
    }

    span {
        font-size: 14px;
        line-height: 18px;
        font-weight: 700;
    }

    strong {
        font-weight: 700;
    }

    .buy-abo-button {
        display: flex;
        font-weight: 600;
        align-items: center;
        justify-content: center;
        background: var(--buy-abo-btn-bg-clr, #0075b0);
        color: var(--buy-abo-btn-txt-clr, #fff);
        padding: 6px 0;
        border-radius: $borderRadiusSmall;
        margin-left: 12px;
        padding: 8px 12px;
    }
}
@media screen and (max-width: 400px) {
    .buy-abo-text-mobile {
        display: inline;
    }
    .buy-abo-text-desktop {
        display: none;
    }
}
@media screen and (min-width: 401px) {
    .buy-abo-text-mobile {
        display: none;
    }
    .buy-abo-text-desktop {
        display: inline;
    }
}

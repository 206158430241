$tablet-width: 600px;
$tablet-breakpoint: 876px;
$desktop-width: 980px;

// header variables
$super-text-color: var(--super-text-color);
$super-header-bg: var(--super-bg-color);
$super-title-bg: var(--title-bg-color);
$link-bg-color: var(--link-bg-color);
$link-bg-hover: var(--link-bg-hover);
$link-bg-active: var(--link-bg-active);
$component-size: 100px;

/*
The super header can be styled for different sections
by modifying the variables like this:

.Ballspark {
  --super-text-color: #{#fff};
  --super-bg-color: #{#f62924};
  --title-bg-color: #{#941916};
  --link-bg-color: #{#941916};
  --link-bg-hover: #{#62100e};
  --link-bg-active: #{#4a0c0b};
}
*/

// Section page variables
$super-section-page-bg: var(--super-section-page-bg);

$gap-regular: 12px;
$gap-xs: $gap-regular * 0.5; // 6px
$gap-small: calc($gap-regular * 4 / 6); // 8px
$gap-medium: calc($gap-regular * 4 / 3); // 16px
$gap-large: $gap-regular * 2; // 24px

$radius-xs: 4px;
$radius-s: 6px;
$radius-l: 24px;

// base font size 16px
$rem-size-13px: 0.8125rem;
$rem-size-17px: 1.0625rem;
$rem-size-18px: 1.125rem;
$rem-size-21px: 1.3125rem;
$rem-size-44px: 2.75rem;

$letter-spacing-s: -0.01em;

// video page variables
$video-teasers-container-bg: var(--video-teasers-container-bg);
$video-teaser-bg: var(--video-teaser-bg);
$video-teaser-text-color-primary: var(--video-teaser-text-color-primary);
$video-teaser-button-bg: var(--video-teaser-button-bg);
$video-teaser-gradient: linear-gradient(
  #00000000 18.34%,
  $video-teasers-container-bg 89.14%
);
$gradient-black-40: linear-gradient(#00000000 0%, #00000040 100%);

/*
  Section overrides. Should be moved into brand
  scope if there is overlap in section naming.
*/
.section-ballspark {
  --super-section-page-bg: #{#f62924};
  --video-teasers-container-bg: #{#310807};
  --video-teaser-bg: #{#190405};
  --video-teaser-text-color-primary: #{#f2f2f2};
  --video-teaser-button-bg: #{#f62924};
}

.bundle-block {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  border-radius: $borderRadius;
  overflow: hidden;

  &:hover {
    border-color: #bbb;
  }

  .article {
    border: none;
    border-radius: 0;

    &.article--breaking {
      .article__text-container {
        .article__kicker-title {
          margin-left: 0;
        }
      }
    }
    &.article--live.article--breaking {
      .article__text-container {
        .article__section {
          padding-left: 0;
          display: inline;
        }
      }
    }
  }

  .subteasers {
    display: flex;
    justify-content: space-evenly;

    .article {
      @media (hover: hover) {
        &:not(.article--dark):hover {
          background-color: #eee;
        }
      }

      margin-bottom: $gapStandard;

      .article__text-container {
        .article__title {
          @include articleTitle-bundle-xsmall();
          font-family: $fontSansSerif;
          font-weight: normal;
          margin-bottom: 0;
        }
      }

      .author__image-container {
        height: 73px;
        width: 73px;
      }

      &:not(.article--live):not(.article--breaking):not(.article--podcast):not(
          [class*='article-section--meninger']
        ) {
        .article__section {
          display: none;
        }
      }
      &.article--podcast {
        .article__section {
          display: block;
        }
        .article__text-container {
          .podcast__image-container,
          .podcastImage {
            height: 73px;
            width: 73px;
          }
        }

        .article__img-container {
          display: none;
        }
      }
      &.article--withAuthorMeta {
        .article__text-container {
          .article__section {
            display: flex;
            flex-direction: column;
            // TODO: We should probably add a generic class to these, in case we need "display: none" all of these in the future
            .article__section--valg-2021 {
              display: none;
            }
          }
          .article__title {
            margin-top: 0;
          }
        }
      }

      &.article--live {
        .article__text-container {
          .article__section::before {
            margin-top: -1px;
            width: 8px;
            height: 8px;
          }
        }
      }

      &.article--breaking {
        .article__img-container {
          border-bottom: none;
        }
      }
    }

    .article__teaser-container a {
      flex-direction: row-reverse;
    }

    .article__text-container {
      .article__kicker-title,
      .article__section {
        @include articleTitle-kicker-label();
        font-family: $fontSansSerif;
        font-weight: 600;
      }

      .article__section {
        font-family: $fontSansSerif;
        font-weight: 600;
      }

      // TODO: add logic to remove author image on bundle subteasers
      .article__titlesAndAuthor-container .author__image-container {
        display: none;
      }
    }

    .article__imageOrAuthor-container {
      .article__img-container {
        display: none;
      }
    }

    .article-section--btmagasinet,
    .article-section--magasin {
      .article__text-container {
        &::before {
          display: none;
        }
      }
    }
  }

  .article--questions {
    border-bottom: none !important;
    .article__teaser-container {
      flex: none;
      width: 60%;
      padding-right: $gapStandard;
      .video-box {
        height: 206px;
      }
    }
    .article-divider {
      margin: 0;
    }
    .highlighted-comments-container {
      flex: none;
      width: 35%;
      padding-left: $gapStandard;
      .highlighted-comments-outer {
        height: 75%;
        width: auto;
      }
    }
  }

  &.large {
    .subteasers {
      flex-direction: row;

      .article {
        margin-bottom: 0;

        &.article--breaking {
          .article__text-container {
            .article__section {
              &::before {
                content: 'Nå';
                color: white;
                padding: 1px 3px 0px 3px;
                width: auto;
                height: auto;
                font-size: 14px;
              }
            }
          }
        }
      }

      // 2 subteasers - "3" because of 1 article-divider
      .article:first-child:nth-last-child(3),
      .article:first-child:nth-last-child(3) ~ .article {
        width: 50%;

        .article__img-container {
          display: none;
        }
      }

      // 3 subteasers - "5" because of 2 article-dividers
      .article:first-child:nth-last-child(5),
      .article:first-child:nth-last-child(5) ~ .article {
        width: 33%;
        .article__img-container,
        .video-container {
          display: none;
        }
      }
    }

    .article-divider {
      width: 1px;
      height: auto;
      margin: $gapSmall 0;
    }

    &.bundle-x2 {
      .subteasers {
        .article__img-container {
          margin: $gapStandard;
        }
      }

      .article--live {
        .article__text-container {
          .article__kicker-title,
          .article__section {
            display: flex;
            gap: 4px;
            align-items: center;
          }
        }
      }

      .article__text-container {
        .article__kicker-title {
          font-weight: 400;
        }
        .article__section {
          font-weight: 500;
        }
      }

      .article--small {
        .article__title {
          @include articleTitle-bundle-medium();
          font-family: $fontHeadline;
          font-weight: $fontHeadlineWeight;
          margin-bottom: 0;
        }
      }
      .article--breaking {
        .article__section {
          @include articleTitle-section-label-big();
        }
      }
    }
  }

  &.small {
    .article {
      height: auto;
    }

    .subteasers {
      flex-direction: column;

      .article__img-container,
      .video-container video {
        max-height: 55px;
        max-width: 93px;
        padding: $gapStandard;
        padding-left: 0;
        img {
          border-radius: $borderRadiusSmall;
          object-fit: cover;
        }
      }
      .article:last-child {
        margin-bottom: 0;
      }
    }
    .article-divider {
      width: calc(100% - 24px);
      height: 1px;
      margin: 0 auto;
    }
  }

  &.mobile {
    .article {
      height: auto;

      &.article--large,
      &.article--medium {
        .article__title {
          @include articleTitle-h4-mobile();
        }
        .article__img-container {
          margin-left: 0;
          margin-right: 0;
        }
      }
    }
    .article__kicker-title {
      font-weight: 500;
    }

    .article-divider {
      width: calc(100% - 24px);
      height: 1px;
      margin: 0 auto;
    }

    .subteasers {
      flex-direction: column;

      .article {
        margin-bottom: 0;
        padding-bottom: $gapStandard;

        &.article--large,
        &.article--medium {
          .article__title {
            @include articleTitle-bundle-xsmall();
          }
        }
      }

      .article__text-container {
        margin-bottom: 0;

        .article__kicker-title {
          @include articleTitle-kicker-label-mobile-bundle();
          font-weight: 500;
        }
        .article__title {
          @include articleTitle-bundle-small();
          font-family: $fontHeadline;
        }
      }

      .article__img-container,
      .video-container video {
        max-width: 140px;
        aspect-ratio: 1.75;
        margin-right: $gapStandard;
        margin-top: $gapStandard;
        border-radius: $borderRadiusSmall;
        img {
          object-fit: cover;
        }
      }
    }
    .article--dark + .article-divider {
      margin-top: 0;
    }
    .article--questions {
      .mm-container {
        height: 197px;
      }
      .article-divider {
        margin: 0 auto;
      }
      .article__teaser-container {
        width: 100%;
        padding-right: 0;
      }
      .highlighted-comments-container {
        width: auto;
      }
    }
  }

  // Edgecase - This should not happend on purpose, but it will for a
  // few moments when the frontboss is looking for the second article
  //to put in the subteasers
  &.bundle-x2:not(.mobile) {
    .subteasers {
      .article {
        .article__teaser-container a {
          flex-direction: row;
        }
        .article__img-container,
        .video-container {
          height: 82px;
          width: 123px;
          padding-bottom: 0;
          padding-top: 0;
          padding-right: 0;
          margin: 12px 0 12px 12px;
          img,
          video {
            border-radius: $borderRadiusSmall;
          }
        }
      }
    }
    .article-divider {
      width: calc(100% - 24px);
      height: 1px;
      margin: 0 auto;
    }
  }

  &.bundle-x2 &:not(.subteasers) {
    .article__imageOrAuthor-container {
      .article__img-container {
        display: block;
      }
      .author__image-container {
        display: none;
      }
    }
    .article__text-container {
      .author__image-container {
        display: block;
      }
    }
  }
  &:not(.mobile) {
    .subteasers {
      .article--questions {
        padding: 0;
        .article__teaser-container {
          width: 100%;
        }
        .article__text-container {
          margin: 12px;
        }
      }
    }
    .article--questions {
      .article-divider {
        height: auto;
        width: 1px;
      }
    }
  }
}

//Mobile main video teaser
.bundle-block.mobile > .article--small.article--videoFrontTeaser a {
  flex-direction: column;
}

//Desktop subteaser video teaser
.row .bundle-block .subteasers .article--small.article--videoFrontTeaser a {
  flex-direction: row-reverse;
}

.bundle-dark {
  background-color: $darkskinBackgroundColor;
  border-color: $darkskinBorderColor;
  .article-divider {
    background-color: $darkskinDividerColor;
  }
}

// Mobile bundle major news
.bundle-block.mobile.bundle-major-news {
  margin-left: 0;
  margin-right: 0;
  .article-tip-us {
    margin-top: 0;
  }
  .article .article-tip-us {
    display: none;
  }
  .article--large {
    .article__section,
    .article__kicker-title {
      @include articleTitle-h6-mobile;
      font-weight: 600;
      margin-bottom: ($gapSmall + 2);
      &:before {
        @include articleTitle-h5-mobile;
      }
    }
    .article__title {
      font-weight: $fontMajorNewsWeight;
      // maybe add hyphens or word-wrap for long words on mobile?

      @include articleTitleShort {
        @include articleTitle-majorNews-mobile-large();
      }
      @include articleTitleMedium {
        @include articleTitle-majorNews-mobile-medium();
      }
      @include articleTitleLong {
        @include articleTitle-majorNews-mobile-small();
      }
    }
  }
  .subteasers {
    .article {
      .article__text-container {
        .article__title {
          @include articleTitle-majorNews-mobile-xsmall();
          font-weight: 500;
        }
      }
    }
  }
}

// Desktop bundle major news
.row.bundle-block.bundle-major-news {
  .article-tip-us {
    margin: 0 $gapStandard + 4px $gapStandard + 4px;
    width: fit-content;
  }
  .article .article-tip-us {
    display: none;
  }
  .article.article--major-news {
    margin-bottom: 0px;

    .article__img-container-container {
      flex-basis: calc(50% + ($gapStandard - 4px));
      margin: $gapStandard + 4px;
      margin-right: 0px;
      border-radius: $borderRadiusSmall;
      overflow: hidden;
    }
    .article__img-container {
      img {
        height: 100%;
        object-fit: cover;
      }
    }
    .article__text-container {
      flex-basis: calc(50% - ($gapStandard - 4px));
      padding: $gapStandard 0;
    }
  }
  .article-divider {
    background-color: $borderColor;

    &.horizontal {
      height: 1px;
      width: unset;
      margin-left: $gapStandard + 4px;
      margin-right: $gapStandard + 4px;
    }
    &.vertical {
      width: 1px;
      margin-top: $gapStandard + 4px;
      margin-bottom: $gapStandard + 4px;
    }
  }

  .subteasers {
    display: flex;
    flex-direction: row;
    position: relative;

    .article {
      margin-bottom: 0px;
      .article__img-container {
        img {
          height: auto;
          border-radius: $borderRadiusSmall;
        }
      }
      .article__text-container {
        .article__title {
          font-family: $fontHeadline;
          font-weight: 500;
        }
      }
    }
  }

  &.bundle-x2 {
    .article {
      .article__img-container-container {
        border-radius: $borderRadiusSmall 0 0 $borderRadiusSmall;
      }
      &.article--small {
        .article__teaser-container a {
          margin: $gapStandard + 4px;
          height: unset;
          flex-direction: row-reverse;
        }
        .article__img-container {
          flex: 1 1 23%;
          height: fit-content;
          width: unset;
          margin: 0;
          display: flex;

          img {
            width: 100%;
            height: unset;
            border-radius: 0 $borderRadiusSmall $borderRadiusSmall 0;
          }
        }
        .article__text-container {
          flex: 1 1 75%;

          .article__title {
            @include articleTitleShort {
              @include articleTitle-majorNews-subteaser-medium();
            }
            @include articleTitleMedium {
              @include articleTitle-majorNews-subteaser-medium();
            }
            @include articleTitleLong {
              @include articleTitle-majorNews-subteaser-medium();
            }
          }
        }
      }
    }
  }
  &.bundle-x3 {
    .subteasers {
      margin: $gapStandard + 4px;

      .article-divider {
        margin: 0;
      }

      .article {
        &.article--small {
          flex: 1;
          .article__teaser-container {
            margin: $gapStandard + 4px;
          }
          .article__img-container {
            flex-basis: 39%;
          }
          .article__text-container {
            margin: 0 ($gapStandard - 4px) 0 0;

            .article__title {
              @include articleTitleShort {
                @include articleTitle-majorNews-subteaser-small();
              }
              @include articleTitleMedium {
                @include articleTitle-majorNews-subteaser-small();
              }
              @include articleTitleLong {
                @include articleTitle-majorNews-subteaser-small();
              }
            }
          }
        }
      }
    }
  }
}

.ch-sub-links {
  padding-top: 30px;
}

.super-header {
  display: flex;
  justify-content: center;
  background-color: $super-header-bg;
  overflow: hidden;

  .super-header-content-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: unset;
    font-family: $fontSansSerif;
    font-weight: normal;
    color: $super-text-color;
    padding: $gapStandard;
    box-sizing: border-box;
    > * {
      max-width: 980px;
    }
  }

  .super-title-container {
    box-sizing: border-box;
    margin: 0;
    .super-logo {
      height: 5rem;
    }
    .title-cover {
      display: none;
    }
  }

  .super-links-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: $gapSmall;
    max-width: 100%;
    box-sizing: border-box;
    font-size: 0.94rem;

    .super-link {
      position: relative;
      display: flex;
      flex: 1;
      flex-direction: column;
      box-sizing: border-box;
      justify-content: flex-end;
      font-weight: 700;
      line-height: 100%;
      white-space: nowrap;
      background-color: $link-bg-color;
      border-radius: 16px 16px 16px 0;
      overflow: hidden;
      min-width: fit-content;
      min-height: $component-size * 0.75;
      padding: $gapSmall;
      gap: $gapSmall;

      .link-icon {
        height: 1.2rem;
        width: fit-content;
        opacity: 66%;
        z-index: 2;
      }
      .link-title {
        z-index: 2;
      }
      .link-cover-img {
        height: 100%;
        width: 100%;
        margin: -$gapSmall;
        position: absolute;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        opacity: 60%;
      }
      .link-bg-art {
        position: absolute;
        margin: -$gapSmall;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
      }
      .link-bg-icon {
        mix-blend-mode: soft-light;
        transform: rotate(12deg);
        opacity: 60%;
        width: $component-size;
        height: $component-size;
        right: 15%;
        top: -10%;
      }
    }
    .link-0 {
      flex: 0 0 $component-size * 1.28;
      min-height: $component-size * 1.28;
    }
    .link-1 {
      flex: 1 1 calc(100% - ($component-size * 1.4));
      min-height: $component-size * 1.28;
    }
    .link-2 {
      flex: 0 0 $component-size * 1.91;
    }
    .link-3 {
      flex: 1 1 calc(100% - ($component-size * 2));
    }
  }

  @media (min-width: $tablet-width) {
    margin-top: -14px;
    margin-bottom: $gapStandard;

    .super-header-content-wrapper {
      flex-direction: row;
      gap: $gapSmall;
      padding: $gapStandard * 2 $gapStandard $gapStandard;
    }

    .super-title-container {
      position: relative;
      display: flex;
      width: $component-size * 2.09;
      min-width: fit-content;
      padding: $gapSmall;
      border-radius: 16px;
      overflow: hidden;
      background-color: $super-title-bg;
      align-items: center;
      justify-content: center;

      .super-logo {
        height: 4.5rem;
        z-index: 2;
      }
      .super-title {
        z-index: 2;
      }
      .title-cover {
        display: block;
        position: absolute;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }
    }

    .super-links-container {
      flex: 1;

      .super-link {
        padding: $gapStandard;

        &:hover {
          background-color: $link-bg-hover;
          .link-cover-img {
            opacity: 100%;
          }
        }
        &:active {
          background-size: 106%;
          background-color: $link-bg-active;
          .link-cover-img {
            background-size: 106%;
          }
          .link-bg-art {
            display: none;
          }
        }
        .link-cover-img {
          margin: -$gapStandard;
        }
        .link-bg-art {
          margin: -$gapStandard;
        }
      }
      .link-1 {
        flex-basis: calc(100% - $component-size * 3.3);
      }
    }
  }
  @media (min-width: $tablet-breakpoint) {
    .super-header-content-wrapper {
      .super-title-container {
        flex: 1;
        .super-logo {
          height: 6.5rem;
        }
      }
      .super-links-container {
        flex: 0 0 $component-size * 5.49;
        font-size: 1.5rem;

        .super-link {
          .link-bg-icon {
            width: $component-size * 1.3;
            height: $component-size * 1.3;
          }
        }
        .link-0,
        .link-1 {
          height: $component-size * 1.92;
        }
        .link-2,
        .link-3 {
          height: $component-size * 0.95;
        }
        .link-0 {
          flex-basis: $component-size * 1.92;
        }
        .link-2 {
          flex-basis: $component-size * 2.73;
        }
        .link-3 {
          flex: 1 1 calc(100% - ($component-size * 2.9));
        }
      }
    }
  }
  @media (min-width: $desktop-width) {
    .super-header-content-wrapper {
      max-width: $desktop-width;
      padding: $gapStandard * 2 0 $gapStandard * 2;

      .super-title-container {
        .super-logo {
          height: 8rem;
        }
      }
    }
  }
}

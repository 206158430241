@import '../../regional/scss/super-sections/default-variables';

@import '../../regional/scss/super-sections/header';
@import '../../regional/scss/super-sections/subsectionandarticle-header';
@import '../../regional/scss/super-sections/super-section-page';

/* variable overrides for BT sections */
.Ballspark {
  --super-text-color: #{#fff};
  --super-bg-color: #{#f62924};
  --title-bg-color: #{#941916};
  --link-bg-color: #{#941916};
  --link-bg-hover: #{#62100e};
  --link-bg-active: #{#4a0c0b};
}

@import '../../../benefits/scss/_teaser-widget.scss';
@import '../../../../src/benefits/views/components/common-components/BenefitsMenu/benefits-section-selector';

.widget-benefits-widget.regional-widget {
    background-color: $color-fordel-background;
    width: 100%;
    max-width: 100%;
    position: relative;

    @media screen and (max-width: 1024px) {
        width: 100vw;
    }
}

.benefit-frontpage-wrapper {
    --padding-x: 12px;
    padding: 24px 12px;
    font-family: 'Inter', sans-serif;
    margin: 12px 0px;
    width: auto;
    box-sizing: border-box;
    position: relative;
    z-index: 1;

    .benefits-frontpage-widget__container {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 980px;
        gap: 12px;

        .benefits-frontpage-widget__teasers {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 12px;
        }

        .benefits-frontpage-widget__footer {
            width: 100%;
            display: flex;
            justify-content: center;
            .benefits-frontpage--footer-container {
                width: 100%;
                display: flex;
                gap: 12px;
                align-items: center;
                margin-top: 12px;

                .benefits-frontpage--footer-logo {
                    flex: 1 0 auto;
                    justify-self: right;

                    > img {
                        height: 32px;
                    }
                }
                .benefits-frontpage--footer-image {
                    flex: 1 0 auto;
                }
                .benefits-frontpage--footer-info {
                    flex: 2 0 auto;
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 24px;
                    color: $color-fordel-widget-title;
                }
                .benefits-frontpage--footer-link {
                    flex: 1 0 auto;
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 24px;
                    color: $color-fordel-widget-title;
                    display: flex;
                    justify-content: end;
                }
                .benefits-frontpage--footer-link-mobile {
                    display: none;
                    color: $color-fordel-widget-title;
                }
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    .benefit-frontpage-wrapper {
        .benefits-frontpage-widget__container {
            .benefits-frontpage-widget__footer {
                display: block;

                .benefits-frontpage--footer-container {
                    justify-content: space-between;
                    width: auto;
                    .benefits-frontpage--footer-logo {
                        padding: 0px;
                        width: min-content;
                        flex: 0 0 auto;
                    }
                    .benefits-frontpage--footer-image {
                        display: none;
                    }
                    .benefits-frontpage--footer-info {
                        display: none;
                    }
                    .benefits-frontpage--footer-link {
                        display: none;
                    }
                    .benefits-frontpage--footer-link-mobile {
                        display: inline;
                        padding: 10px 10px 10px 0px;
                        flex: 0 0 auto;
                        width: min-content;
                        white-space: nowrap;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .benefits-frontpage-widget__teasers {
        a:not(:first-child) {
            display: none;
        }
    }
}

// publication overrides
.benefit-frontpage-wrapper
    .benefits-frontpage-widget__container
    .benefits-frontpage-widget__footer {
    .sa.benefits-frontpage--footer-container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        .benefits-frontpage--footer-logo {
            display: none;
        }

        .benefits-frontpage--footer-info {
            color: black;
            justify-self: center;
        }

        .benefits-frontpage--footer-link {
            color: black;
            justify-self: end;
        }

        .benefits-frontpage--footer-image > img {
            margin-top: 40px;
            margin-bottom: 32px;
            width: 200px;
            height: 130px;
            rotate: -10deg;
            border-radius: 4px;
        }

        .benefits-frontpage--footer-link-mobile {
            margin-left: auto;
        }

        @media screen and (max-width: 1024px) {
            display: flex;

            .benefits-frontpage--footer-logo {
                display: block;

                > img {
                    height: 28px;
                }
            }
        }
    }
}

.articles-container {
  @media #{$queryDesktop} {
    width: 980px;
    padding: 14px;
    margin: 0 auto $gapLarge;
    box-sizing: content-box;
  }
}

.row {
  display: flex;
  flex-direction: row;
  margin-right: 0px;
  margin-bottom: $gapStandard;

  &:empty {
    margin-bottom: 0px;
  }

  .column {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: $gapStandard;

    &:last-child {
      margin-right: 0px;
    }
  }

  .article {
    flex: auto;
    margin-bottom: $gapStandard;

    &:last-child {
      margin-bottom: 0px;
    }

    &.article--large {
      .article__title--long {
        @include articleTitle-h3();
      }
      .article__title--medium {
        @include articleTitle-h2();
      }
      .article__title--short {
        @include articleTitle-h1();
      }

      &.article-section--btmagasinet,
      &.article-section--magasin {
        .article__title--long {
          letter-spacing: -0.62px;
        }
        .article__title--medium {
          letter-spacing: -0.8px;
        }
        .article__title--short {
          letter-spacing: -0.9px;
        }
      }

      .article__text-container {
        margin: $gapStandard + 4px;
      }
    }

    &.article--medium {
      .article__title--long {
        @include articleTitle-h4();
      }
      .article__title--medium {
        @include articleTitle-h3();
      }
      .article__title--short {
        @include articleTitle-h2();
      }

      &.article-section--btmagasinet,
      &.article-section--magasin {
        .article__title--long {
          letter-spacing: -0.51px;
        }
        .article__title--medium {
          letter-spacing: -0.62px;
        }
        .article__title--short {
          letter-spacing: -0.8px;
        }
      }
    }

    &.article--small {
      .article__title--long {
        @include articleTitle-h4();
      }
      .article__title--medium {
        @include articleTitle-h4();
      }
      .article__title--short {
        @include articleTitle-h3();
      }

      &.article-section--btmagasinet,
      &.article-section--magasin {
        .article__title--long {
          letter-spacing: -0.51px;
        }
        .article__title--medium {
          letter-spacing: -0.51px;
        }
        .article__title--short {
          letter-spacing: -0.62px;
        }
      }
    }

    &.article--xsmall {
      .article__title--long {
        @include articleTitle-h5();
      }
      .article__title--medium {
        @include articleTitle-h5();
      }
      .article__title--short {
        @include articleTitle-h4();
      }

      &.article-section--btmagasinet,
      &.article-section--magasin {
        .article__title--long {
          letter-spacing: -0.3px;
        }
        .article__title--medium {
          letter-spacing: -0.3px;
        }
        .article__title--medium {
          letter-spacing: -0.51px;
        }
      }
    }

    .article__text-container {
      .article__kicker-title,
      .article__section {
        display: flex;
        gap: $kicker-gap;
        margin-bottom: $kicker-margin;
      }
      .article__section-kicker-container {
        margin-bottom: $kicker-margin;
        .article__kicker-title,
        .article__section {
          margin-bottom: 0;
        }
      }
      .author__image-container {
        float: right;
        margin: 0 0 $gapStandard $gapStandard;
      }
    }
    &.article--dark:hover {
      background-color: $darkSkinHoverBackground;
    }
    &:hover {
      border-color: #bbb;
    }
    &:focus {
      border-color: #333;
      .article__title {
        color: #000;
      }
    }
    &:not(.article--dark) .timeline--item:hover {
      background-color: #f6231e25;
      border-bottom-color: #ffffff00; // Invisible border, still 1px high
    }
    &.article--dark .timeline--item:hover {
      background-color: #f6231e50;
      border-bottom-color: #ffffff00; // Invisible border, still 1px high
      .timeline--date {
        color: #f6231e;
      }
    }
    .article__teaser-container {
      margin-bottom: auto;
    }

    &.article--major-news {
      &.article--videoFrontTeaser {
        .article-tip-us {
          margin: 0;
        }
      }
      &:not(.article--noImage) {
        .article__img-container-container {
          flex-basis: 35%;
          height: unset;
          margin: 0;
          border-right-width: 0;
          border-radius: 0;
        }
        .article__text-container {
          flex: 3;
          &.no-margin {
            margin: 0;
          }
        }
      }
      .article__teaser-container {
        display: flex;
        .article__text-container {
          margin: $gapStandard + 4px;
          &.no-margin {
            margin: 0;
          }
          .article__kicker-title,
          .article__section {
            margin-bottom: $gapStandard;

            @include articleTitle-h6-mobile;
            &:before {
              @include articleTitle-h5-mobile;
            }
          }
          .article__title {
            font-weight: $fontMajorNewsWeight;

            @include articleTitleShort {
              @include articleTitle-majorNews-large();
            }
            @include articleTitleMedium {
              @include articleTitle-majorNews-medium();
            }
            @include articleTitleLong {
              @include articleTitle-majorNews-small();
            }
          }
        }
      }
    }
  }

  &.vertical-x1-ad,
  &.collage-x3,
  &.vertical-x3 {
    .article--small,
    .article--medium {
      .article__imageOrAuthor-container {
        .article__img-container {
          display: block;
        }
        .author__image-container {
          display: none;
        }
      }

      .article__text-container {
        .author__image-container {
          display: block;
          margin: 0 0 $gapSmall * 0.5 $gapSmall;
          width: 74px;
          height: 74px;
        }
      }
    }
  }
  &.collage-x4,
  &.top-60-40-block .column-60 {
    .bundle-x3,
    .bundle-x4 {
      .subteasers {
        .author__image-container {
          display: none;
        }
      }
    }
  }

  &.bundle-large {
    .side-column {
      .article--xsmall,
      .article--small,
      .article--medium,
      .article--large {
        .article__title {
          @include articleTitle-bundle-xsmall();
          font-family: $fontSansSerif;
          font-weight: normal;
        }
      }
    }
  }

  .column.column--medium {
    flex: 1;
    flex-shrink: initial;
  }

  // TODO: tmp design tweaks for author image discussion
  .author__image-container {
    width: 93px;
    height: 93px;
    border-radius: 50%;
    overflow: hidden;
    margin: $gapStandard $gapStandard 0 $gapStandard;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .podcast__image-container,
  .article__imageOrPodcast-container {
    display: block;
    .podcastImage {
      background-size: contain;
      background-repeat: no-repeat;
      height: 74px;
      width: 74px;
    }
  }

  .podcast__image-container {
    float: right;
    display: block;
    overflow: hidden;
    margin-left: $gapStandard * 0.5;
    border-radius: $gapStandard * 0.5;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);
  }

  .article--videoFrontTeaser {
    &.article.article--small a {
      flex-direction: column;
    }
    .video-container {
      position: relative;
      &.autoplay .video-icon {
        display: none;
      }
      video {
        width: 100%;
        position: inherit;
      }
      .video-icon {
        position: absolute;
        display: flex;
        bottom: 8px;
        margin: 8px;
        img {
          width: 44px;
          height: 44px;
        }
      }
    }
  }

  // Hides podcast image (which replaces main image) as default
  .article__imageOrPodcast-container .podcastImage {
    display: none;
  }

  // Shows podcast image as main image, when there is podcast teaser in collage-x4 & vertical-small-x2
  &.collage-x4 .column--small .article__imageOrPodcast-container .podcastImage,
  &.vertical-small-x2 .article__imageOrPodcast-container .podcastImage {
    margin: $gapStandard $gapStandard 0 $gapStandard;
    border-radius: $gapStandard * 0.5;
    display: block;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.16);
  }

  // Hides teaser image, when there is podcast teaser in collage-x4 & vertical-small-x2
  &.collage-x4 .column--small {
    .podcast__image-container {
      display: none;
    }
  }

  &.collage-x4 .column--small .article__imageOrPodcast-container .podcastImage {
    height: 94px;
    width: 94px;
  }

  &.vertical-small-x2 {
    .article__imageOrPodcast-container .article__img-container {
      display: none;
    }
    .podcast__image-container {
      display: none;
    }
  }

  &.collage-x3 {
    .column--big {
      .podcast__image-container .podcastImage {
        height: 94px;
        width: 94px;
      }
    }
  }

  &.collage-x4 {
    .column--big {
      .podcast__image-container .podcastImage {
        height: 94px;
        width: 94px;
      }
    }
  }

  .column--small {
    .article--small,
    .article--medium {
      .article__imageOrAuthor-container .author__image-container {
        margin: $gapStandard $gapStandard 0 $gapStandard;
      }

      .article__text-container .author__image-container {
        margin: 0 0 $gapSmall * 0.5 $gapSmall;
        width: 74px;
        height: 74px;
      }
    }
  }

  .article--xsmall,
  .article--small,
  .article--medium {
    .author__image-container {
      margin: $gapStandard;
      width: 93px;
      height: 93px;
    }

    .article__imageOrAuthor-container {
      .article__img-container {
        display: none;
      }
    }

    .article__text-container {
      .author__image-container {
        display: none;
      }
    }
  }

  .article--full-width,
  .article--large {
    .article__imageOrAuthor-container {
      .author__image-container {
        display: none;
      }
    }
  }

  // hack to overwrite core css
  .article--highlightedComments {
    border-bottom: 1px solid $borderColor !important;
    &:hover {
      border-bottom-color: #bbb !important;
    }
  }
  .article--questions {
    display: flex;
    flex-direction: row !important;
    padding: 16px;
    .article__teaser-container {
      flex-direction: column;
      flex: 2;
      .video-box,
      .text-box {
        width: 100%;
      }
      .video-box {
        border-radius: $borderRadius;
        overflow: hidden;
        height: 344px;
        .mm-container {
          height: 100%;
        }
      }
      .article__text-container {
        margin: $gapStandard 0;
        .article__title {
          @include articleTitle-h3();
        }
      }
      .article__img-container {
        border-radius: $borderRadius;
      }
    }
    .highlighted-comments-container {
      flex: 1;
      font-family: Inter;
      .comments-header {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: #0075b0;
        left: 0;
        justify-content: flex-start;
        .comments-counter {
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #0075b0;
          left: 0;
          margin-right: 4px;

          &::before {
            content: none;
          }
        }
      }
      .comments-lead-text {
        font-size: 14px;
        line-height: 17px;
        color: #666666;
        margin-top: 4px;
      }
      .highlighted-comments-outer {
        height: 80%;
      }
      .comment-text {
        width: 100%;
        .comment-text-inner {
          border-radius: 16px;
          width: 100%;
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: -0.01em;
          color: #333333;
          border-width: 16px;
        }
        &:after {
          content: none;
        }
      }
      .additional-info {
        .comment-author {
          font-size: 12px;
          line-height: 14px;
          color: #666;
        }
      }
      .go-to-comment {
        color: #0075b0;
        border-color: #0075b0;
      }
    }
    .article-divider {
      height: auto;
      width: 1px;
      margin: 0 16px;
    }
  }

  .mm-box-container,
  .mm-front-box,
  .mm-inner-content,
  .mm.boxes,
  .mm-box,
  .mm-box-content {
    width: 100%;
  }
  .mm-boxes {
    display: flex;
    .mm-box-content a {
      .jwplayer {
        height: 0 !important;
        padding-bottom: 56.25%;
        .jw-controls,
        .jw-controls-backdrop {
          display: none;
        }
      }
      .mm-btn {
        display: none;
      }
    }
  }
}

.braze.content-card {
  max-width: 980px;
  margin: 12px auto;

  @media #{$queryTablet} {
    padding: 0 12px;
  }
}
